export const envVariables = {
	// baseUrl: 'http://35.230.86.55:8000',
	// baseUrl: 'https://apicleaner.pricemaker.io',
	// baseUrl: 'https://pbcleaner-tqiyjbycsa-tl.a.run.app',
	// baseUrl: 'https://bpstd-soprole-klzmsj52xq-tl.a.run.app',
	// baseUrl: 'https://bpstd-soprole-dev-klzmsj52xq-tl.a.run.app',
	// baseUrl: 'https://pbstd-soprole-service-dev.pricemaker.io',
	// baseUrl: 'https://pbstd-soprole-service.pricemaker.io',
	baseUrl: 'https://pbstd-demo-api.pricemaker.io/es',
}
