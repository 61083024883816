import React, { useState , useEffect} from 'react'
import { useParams } from 'react-router-dom'
import {
	Header,
} from '../../componentsRef'
import {Spinner} from '../../componentsRef/ui'
import {LogoutPopup} from '../LogoutPopup'
import TableauReport from 'tableau-react'

export const DashboardClean = () => {
    const [tokenTableau, setTokenTableau] = useState()
	const [LogoutState, setLogoutState] = useState(false)
    const { codigoProducto, cadena } = useParams()
    // PF/Promo/AnlisisROI
	let url_ = 'https://tableauserver-azure.pricemaker.io/t/Demo/views/';
	// url_ = url_+'VistaProducto/producto';
	url_ = url_+'Vista_Producto_demo/producto';
    let parameters_ = {}
    parameters_ = {
        "Codigo Producto": [codigoProducto], 
        "cadena": [cadena]
    }
		
	
	/*
            parameters_ = {
			ID1: [dataProduct.code_product + '-' + data.cadena_details.name_customer],
		}
    */
	const fetchApi = async (tradicional = false) => {
		const url = 'https://optimusprice.pricemaker.io/api/getChartTrust'
		// https://tableauserver-azure.pricemaker.io/t/Soprole/views/basetest/titi2
		const dtargetSite = 'Demo'
		// const project = 'VistaProducto'
		const project = 'Vista_Producto_demo'
		const page = 'producto'
		const response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				token: window.sessionStorage.getItem('token') + '-2chzRY3F3I9@',
				tabs_permission: 2,
				dtarget_site: dtargetSite,
				project: project,
				page: page,
			}),
		})

		console.log(response.status)
		const responseJSON = await response.json()
		setTokenTableau(responseJSON.token_server)
	}

    useEffect(() => {
		fetchApi()
    }, [])

	return (
        <>
			{LogoutState && <LogoutPopup setLogoutState={setLogoutState} />}
            
            {!tokenTableau ? (
								<Spinner />
							) : (
								<TableauReport
									url={url_}
									token={tokenTableau}
									options={{
										// width: "100%",
										width: "600px",
										height: "100vh",
									}}
									query="?:jsdebug=n"
									parameters={parameters_}
								/>
							)}
            </>
	)
}

